import { toast } from 'react-toastify'
import { navigate } from 'gatsby'

import { putCall } from '../services/api/calls'
import { isBrowser } from '../components/Auth'

/**
 * Crea llamada y redirecciona a sesión.
 * Si se pasa el id de paciente, se redirecciona con él en la url.
 * @param {string} phone Teléfono a crear llamada
 * @param {int | undefined} patient_id ID de paciente
 * @returns {Promise}
 */
export function createCall(phone, patient_id) {
  return putCall({ phone })
    .then(res => {
      toast.success(`Llamada #${res.data.id} iniciada con éxito`)
      if (isBrowser()) {
        let url = `/session/?call_id=${res.data.id}`
        if (patient_id) url = `${url}&patient_id=${patient_id}`
        navigate(url)
      }
    })
    .catch(err => toast.error(err.response.data.message))
}
