import React from 'react'
import { Link } from 'gatsby'
import { PatientDetails } from './patient-details'
import Modal from 'react-modal'

import closeIcon from '../../images/close.svg'

export function PatientList ({ patients, sessions, isOpen, onClose, createCall, parameters }) {
  if (!patients || patients.length === 0) {
    return null
  }
  Modal.setAppElement('.layout-container')
  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick={false}>
      <div
        onClick={() => {
          if (onClose) onClose()
        }}
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '24px',
          backgroundImage: `url("${closeIcon}")`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '2rem',
          right: '2rem'
        }}
      />

      <div>
        <div>
          {patients.map(patient => {
            return (
              <PatientDetails
                key={`patient-${patient.patient_id}`}
                patient={patient}
                createCall={createCall}
                sessions={sessions[patient.patient_id]}
                parameters={parameters}
              />
            )
          })}
        </div>
        <div style={{ textAlign: 'center', marginTop: '3rem' }}>
          <Link to='/filters/' className='btn' style={{ fontSize: '19px' }}>
            PACIENTE EXISTENTE
          </Link>
          <span
            className='btn'
            onClick={() => {
              if (createCall) createCall()
            }}
            style={{ fontSize: '19px' }}
          >
            NUEVO PACIENTE
          </span>
        </div>
      </div>
    </Modal>
  )
}
