import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const usePatients = (defaultValue = {}, queryParams = '', deps = []) => {
  const [patients, setPatients] = useState(defaultValue)

  useEffect(() => {
    GET('patients', queryParams).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setPatients(res.body)
      }
    })
  }, deps)

  return patients
}

export default usePatients
