import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useSessions = (queryParams = '', deps = []) => {
  const [sessions, setSessions] = useState([])

  useEffect(() => {
    GET('sessions', queryParams).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setSessions(res.body)
      }
    })
  }, deps)

  return sessions
}

export default useSessions
