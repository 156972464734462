import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'

import './patient-details.scss'

export function PatientDetails ({ patient, sessions, createCall, parameters }) {
  if (!patient) {
    return null
  }

  return (
    <div>
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textDecoration: 'underline',
          lineHeight: 1
        }}
      >
        <span
          onClick={() => {
            if (createCall) createCall(patient.patient_id)
          }}
          style={{
            cursor: 'pointer'
          }}
        >
          {patient.name}
        </span>
      </div>

      <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold'
        }}
      >
        CC. {patient.cc}
      </div>

      <table className='table-details'>
        <thead>
          <tr>
            <th>FECHA</th>
            <th>PSICÓLOGA</th>
            <th>TELÉFONO</th>
            <th>DIAGNÓSTICO</th>
            <th>PROBLEMÁTICA</th>
            <th>PRIORIDAD</th>
            <th>ESTADO</th>
          </tr>
        </thead>
        <tbody>
          {sessions
            ? sessions.map(session => {
              const priority =
              session.id === 0
                ? '-'
                : session.priority === 1
                  ? 'Alta'
                  : session.priority === 2
                    ? 'Media'
                    : 'Baja'
              const author = parameters.authors ? parameters.authors.find(i => i.id === session.author) : ''
              const call = parameters.calls ? parameters.calls.find(i => i.id === session.call_id) : ''
              const diagnostic = parameters.diagnostics ? parameters.diagnostics.find(i => i.session_id === session.id) : ''
              const problematic = parameters.problematics ? parameters.problematics.find(i => i.id === session.problematic_id) : ''

              return (
                <tr key={session.call_id}>
                  <td>{session.date}</td>
                  <td>{author ? author.name : (
                    parameters.authors ? '-'
                      : <SyncLoader color='#000' size='6' loading />)}
                  </td>
                  <td>{call ? call.phone : (
                    parameters.calls ? '-'
                      : <SyncLoader color='#000' size='6' loading />)}
                  </td>
                  <td>{diagnostic ? diagnostic.name : (
                    parameters.diagnostics ? '-'
                      : <SyncLoader color='#000' size='6' loading />)}
                  </td>
                  <td>{problematic ? problematic.name : (
                    parameters.problematics ? '-'
                      : <SyncLoader color='#000' size='6' loading />)}
                  </td>
                  <td>{priority}</td>
                  <td>
                    {session.id === 0
                      ? '-'
                      : session.is_draft
                        ? 'Borrador'
                        : 'Guardada'}
                  </td>
                </tr>
              )
            })
            : (
              <tr>
                <td colSpan='7' className='text-center'><SyncLoader color='#000' loading /></td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  )
}
