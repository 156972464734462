import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'

import GET from '../../dao/get'
import { PatientList } from './patient-list'
import { onlyNumber } from '../../utilities/only-number'
import { createCall } from '../../utilities/create-call'
import { getPatientLastSessions, getSessionsParameters } from '../../services/api/sessions'

export function StartCallHeader () {
  const [phone, setPhone] = useState('')
  const [submiting, setSubmiting] = useState(false)
  const [patients, setPatients] = useState(false)
  const [sessions, setSessions] = useState(false)
  const [parameters, setParameters] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (!patients) return

    const patientIds = patients.map(p => p.patient_id)

    getPatientLastSessions(patientIds, 5).then(res => {
      setSessions(res.data)
    })
  }, [patients])

  useEffect(() => {
    // if (!sessions) return
    if (!sessions || parameters !== false) return

    const params = {}

    // crea lista de problemáticas
    let problematicIds = []
    Object.values(sessions).forEach(sess =>
      problematicIds.push(...sess.map(s => s.problematic_id))
    )
    problematicIds = problematicIds.filter((v, i) => { return v !== 0 && problematicIds.indexOf(v) === i })
    if (problematicIds.length > 0) params.problematic_ids = problematicIds.join(',')

    // crea lista de llamadas
    let callIds = []
    Object.values(sessions).forEach(sess =>
      callIds.push(...sess.map(s => s.call_id))
    )
    callIds = callIds.filter((v, i) => { return v !== 0 && callIds.indexOf(v) === i })
    if (callIds.length > 0) params.call_ids = callIds.join(',')

    // crea lista de autores
    let authorIds = []
    Object.values(sessions).forEach(sess =>
      authorIds.push(...sess.map(s => s.author))
    )
    authorIds = authorIds.filter((v, i) => { return v !== 0 && authorIds.indexOf(v) === i })
    if (authorIds.length > 0) params.user_ids = authorIds.join(',')

    // crea lista de sesiones
    let sessionIds = []
    Object.values(sessions).forEach(sess =>
      sessionIds.push(...sess.map(s => s.id))
    )
    sessionIds = sessionIds.filter((v, i) => { return v !== 0 && sessionIds.indexOf(v) === i })
    if (sessionIds.length > 0) params.session_ids = sessionIds.join(',')

    getSessionsParameters(params).then(res => {
      setParameters(res.data)
    })
  }, [sessions])

  const createCallRedirectSession = patientId => {
    return createCall(phone, patientId).then(() => setSubmiting(false))
  }

  // crea un registro en la tabla calls
  const newCall = () => {
    if (phone.trim().length < 5) {
      toast.warn('El teléfono es obligatorio')
      return
    }

    if (submiting) {
      console.log('Aún se está creando la llamada')
      return
    }

    setSubmiting(true)

    // setSubmiting(true)
    GET('patients', `?action=by-phone&phone=${phone}`).then(res => {
      if (res.status === 204) {
        // si no hay ningún paciente con el número
        createCallRedirectSession()
      } else if (res.status === 200 && res.body.length === 0) {
        // si no hay ningún paciente con el número
        createCallRedirectSession()
      } else if (res.status === 400) {
        if (res.body.message === 'Ocioso confirmado') {
          // popup de ocioso confirmado
          confirmAlert({
            title: 'ESTE PACIENTE ESTÁ IDENTIFICADO COMO OCIOSO',
            message: 'SI CIERRA LA VENTANA SE TERMINARÁ LA LLAMADA',
            buttons: [
              {
                label: 'RECHAZAR',
                onClick: () => setSubmiting(false)
              },
              {
                label: 'CONTESTAR',
                onClick: () => createCallRedirectSession()
              }
            ]
          })
        } else {
          // muestra error de backend
          toast.error(res.body.message)
          setSubmiting(false)
        }
      } else {
        setPatients(res.body)
        setOpenModal(true)
        setSubmiting(false)
      }
    })
      .catch(() => {
        toast.error("No fue posible obtener los datos de pacientes relacionados con el teléfono, intenta iniciar la llamada desde un botón '+SESSION' en la página de filtros")
      })
  }

  return (
    <div className='header-content'>
      <div className='phone-container'>
        <input
          type='text'
          placeholder='Nº TELÉFONO'
          className='textfield phone'
          value={phone}
          onChange={e => setPhone(onlyNumber(e.target.value))}
          onKeyDown={e => {
            if (e.key === 'Enter') newCall()
          }}
        />
      </div>

      <button className='btn btn-inline' onClick={() => newCall()}>
        Empezar llamada
      </button>

      <PatientList
        patients={patients}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        createCall={createCallRedirectSession}
        sessions={sessions}
        parameters={parameters}
      />
    </div>
  )
}
